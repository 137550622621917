import { HighlightOutlined } from "@ant-design/icons";
import { Button, Select, Typography } from "antd";
import { useState } from "react";
import _bank from "../utils/bank.json";

const SelectBank = ({ value, onChange, label, editable }) => {
  const [editing, setEditing] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        paddingTop: 10,
        gap: 40,
      }}
    >
      <Typography.Text>{label || "Tên Ngân hàng"}</Typography.Text>
      <div style={{ flexGrow: "1" }}>
        {editing ? (
          <Select
            defaultOpen
            onDropdownVisibleChange={(open) => {
              if (!open) setEditing(false);
            }}
            value={value}
            style={{ minWidth: "100%" }}
            placeholder="Chọn ngân hàng thụ hưởng"
            onChange={onChange}
          >
            {_bank.banksnapas.map((item) => (
              <Select.Option value={`${item.shortName}`}>
                <div style={{ wordBreak: "break-word" }}>
                  <b> {item.shortName}</b> - <span>{item.vn_name}</span>
                </div>
                {item.logo && (
                  <img src={item.logo} width={50} alt={item.vn_name} />
                )}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <div>
            {value}
            {editable && (
              <Button type="link" onClick={() => setEditing(true)} size="small">
                <HighlightOutlined />
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectBank;
