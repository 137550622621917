import axios from "axios";
import { BASE_URL } from "./utils/constant";
import { Logout } from "./redux/actions/auth";

const api = axios.create({
  baseURL: BASE_URL,
  // baseURL: 'http://localhost:8080/api/v1',
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    "Access-Control-Allow-Origin": "*",
  },
  withCredentials: false,
});

api.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem("access_token");
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error?.response?.status === 401) {
      Logout();
    }
    throw error.response.data.message;
  }
);

export default api;
