/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Col, Input, Row, message } from "antd"
import React from "react"
import { useEffect } from "react"
import { useState } from "react"
import api from "../../api"

function SystemConfig() {
    const [dataToUpdate, setDataToUpdate] = useState({
        telegramUrl: null,
        supportUrl: null
    })

    function handleChangeData(field, value) {
        setDataToUpdate({ ...dataToUpdate, [field]: value })
    }

    useEffect(() => {
        getSystemConfig()
    }, [])

    async function getSystemConfig() {
        const { data } = await api.get(`/systemConfiguration/admin/getSystemConfig`)
        for (const key of Object.keys(dataToUpdate)) {
            dataToUpdate[key] = data?.data?.[key]
        }
        setDataToUpdate({ ...dataToUpdate })
    }
    async function updateSystemConfig() {
        await api.post(`/systemConfiguration/admin/updateConfig`, dataToUpdate);
        message.success("Cập nhật thành công.");
        getSystemConfig()
    }
    return <div>
        <Card>
            <div>
                <Row style={{ padding: "8px" }}>
                    <Col xs={24} md={8} lg={4}>Số điện thoại CSKH</Col>
                    <Col xs={24} md={16} lg={12}>
                        <Input value={dataToUpdate.supportUrl} onChange={(e) => handleChangeData("supportUrl", e.target.value)}></Input>
                    </Col>
                </Row>
                <Row style={{ padding: "8px" }}>
                    <Col xs={24} md={8} lg={4}>Liên kết CSKH</Col>
                    <Col xs={24} md={16} lg={12}>
                        <Input value={dataToUpdate.telegramUrl} onChange={(e) => handleChangeData("telegramUrl", e.target.value)}></Input>
                    </Col>
                </Row>
                <Row style={{ padding: "8px" }}>
                    <Col xs={24} md={8} lg={4}>
                        <Button type="primary" style={{ width: "100%", marginTop: "2rem" }} onClick={updateSystemConfig}>
                            Lưu thông tin
                        </Button>
                    </Col>
                </Row>
            </div>
        </Card>
    </div>
}
export default SystemConfig