import { createBrowserHistory } from "history";
import {
  LOGIN_SUCCESS,
  LOGOUT,
  EXPRIED,
  CLOSE_EXPRIED,
  SET_PROFILE,
} from "../constants";

export const initialLogin = (data) => async (dispatch) => {
  return dispatch({ type: LOGIN_SUCCESS, payload: data });
};
export const Login = (params) => async (dispatch) => {
  localStorage.setItem("access_token", params.access_token);
  localStorage.setItem("role", params.role);
  return dispatch({ type: LOGIN_SUCCESS, payload: {} });
};
export const Logout = () => async (dispatch) => {
  window.localStorage.clear();
  window.location.href = "/auth";
  return dispatch({ type: LOGOUT });
};

export const NotifyExpried = () => async (dispatch) => {
  return dispatch({ type: EXPRIED });
};

export const CloseExpried = () => async (dispatch) => {
  return dispatch({ type: CLOSE_EXPRIED });
};

export const setUserProfile = (payload) => async (dispatch) => {
  return dispatch({ type: SET_PROFILE, payload });
};
