import { DeleteOutlined } from "@ant-design/icons";
import { Col, Image, Modal, Row, message } from "antd";
import { useEffect, useState } from "react";
import dataURItoBlob from "../../../utils/upload";
import Compress from "react-image-file-resizer";
import api from "../../../api";

function ModalShowIdentity(props) {
  const { images, isOpenModal, closeModal, dispatchReload, idUser, profile } =
    props;
  const [allImage, setAllImage] = useState([]);

  useEffect(() => {
    setAllImage(images);
  }, [images]);

  async function handleUploadFile(e, key) {
    const index = allImage.findIndex((item) => item.key === key);
    Compress.imageFileResizer(
      e.target.files[0],
      480,
      480,
      "PNG",
      100,
      0,
      async (uri) => {
        try {
          let formData = new FormData();
          const dataURI = dataURItoBlob(uri);
          formData.append("file", dataURI);
          const { data } = await api.post(`/upload`, formData);
          allImage[index].value = data?.secure_url;
          setAllImage([...allImage]);
        } catch (err) {
          message.error("Đã có lỗi trong việc upload ảnh. Vui lòng thử lại");
        } finally {
        }
      },
      "base64"
    );
  }

  function handleRemoveFile(key) {
    const index = allImage.findIndex((item) => item.key === key);
    allImage[index].value = null;
    setAllImage([...allImage]);
  }

  async function onOk() {
    if (!images[0].value || !images[1].value || !images[2].value) {
      return;
    }
    await api.post("/users/admin/updateProfile", {
      id: idUser,
      data: {
        kyc: {
          ...profile?.kyc,
          [images[0].key]: images[0].value,
          [images[1].key]: images[1].value,
          [images[2].key]: images[2].value,
        },
      },
    });
    message.success("Cập nhật thành công");
    dispatchReload();
    closeModal();
  }
  return (
    <Modal
      title="Ảnh chứng minh thư"
      visible={isOpenModal}
      cancelButtonProps={{ style: { display: "none" } }}
      onCancel={() => closeModal()}
      onOk={() => onOk()}
      style={{ minWidth: "80vw" }}
    >
      <Row style={{ justifyContent: "space-between" }}>
        {allImage.map((item, index) => (
          <Col lg={8} key={index}>
            <div
              style={{
                padding: "8px",
                margin: "4px",
                border: "1px solid #ccc",
                position: "relative",
                height: "300px",
                width: "auto",
                objectFit: "cover",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              {item?.value && (
                <Image
                  src={item?.value}
                  maxHeight={"100%"}
                  maxWidth={"100%"}
                  style={{
                    width: "auto",
                    objectFit: "cover",
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                />
              )}

              {!item?.value && (
                <>
                  <label
                    htmlFor={"input_upload_identity" + index}
                    style={{
                      width: "100%",
                      height: "300px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Tải ảnh lên
                  </label>
                  <input
                    id={"input_upload_identity" + index}
                    type="file"
                    onChange={(e) => handleUploadFile(e, item.key)}
                    accept="image/png, image/gif, image/jpeg"
                    style={{ left: 0, cursor: "pointer", display: "none" }}
                  />
                </>
              )}
              <DeleteOutlined
                style={{
                  position: "absolute",
                  fontSize: "24px",
                  backgroundColor: "red",
                  padding: "2px",
                  borderRadius: "4px",
                  color: "#fff",
                  zIndex: "10",
                  right: "0",
                  top: "0",
                }}
                onClick={() => handleRemoveFile(item.key)}
              />
            </div>
          </Col>
        ))}
      </Row>
    </Modal>
  );
}

export default ModalShowIdentity;
