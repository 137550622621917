import React, { useState } from "react";
import { Avatar, Typography, Button, Spin, message, Tag, Image } from "antd";
import { HighlightOutlined } from "@ant-design/icons";
import api from "../../api";
import SelectBank from "../../components/SelectBank";
import _bank from "../../utils/bank.json";
import ModalShowIdentity from "./components/ModalShowIdentity";

const ItemText = ({ changeFunc, label, value, editable }) => {
  const [ROLE] = React.useState(localStorage.getItem("role"));

  return (
    <span
      style={{
        display: "flex",
        justifyContent: "space-between",
        paddingTop: 10,
      }}
    >
      <Typography.Text style={{ flex: 1, paddingRight: 10 }}>
        {label}{" "}
      </Typography.Text>
      <Typography.Text
        style={{ flex: 2 }}
        editable={
          editable && ROLE == "ROOT"
            ? {
                icon: <HighlightOutlined />,
                tooltip: "Click để chỉnh sửa",
                onChange: (e) => changeFunc(e),
              }
            : false
        }
      >
        {value}
      </Typography.Text>
    </span>
  );
};
export default function Kyc({ data, dispatchReload }) {
  const [ROLE] = React.useState(localStorage.getItem("role"));
  const [isModalShowIdentity, setIsModalShowIdentity] = useState(false);

  async function updateItem(field, value) {
    let obj = {};
    obj[field] = value;
    await api.put(`/users/${data._id}`, { ...data.kyc, ...obj });
    message.success("Cập nhật thành công");
    dispatchReload();
  }

  async function updateBank(field, value) {
    let obj = {};

    obj = { ...data.kyc.bank };
    obj[field] = value;
    if (field === "bankName") {
      for (let index = 0; index < _bank.banksnapas.length; index++) {
        if (_bank.banksnapas[index].shortName === value) {
          obj["logo"] = _bank.banksnapas[index].logo;
          break;
        }
      }
    }

    await api.put(`/users/${data._id}`, { ...data.kyc, bank: obj });
    message.success("Cập nhật thành công");
    dispatchReload();
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          background: "#fff",
          padding: 20,
          borderRadius: 10,
          flex: 2,
          marginRight: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            padding: "20px 0",
            flex: 1,
          }}
        >
          {data.kyc.status === "accepted" && (
            <Tag color="success" style={{ margin: 10 }}>
              Đã xác minh
            </Tag>
          )}
          <Avatar size={120} src={data.avatar} />
          <Phone
            userId={data?._id}
            phone={data?.phone}
            editable
            reload={dispatchReload}
          />
          <Typography.Text
            strong
            editable={
              ROLE == "ROOT"
                ? {
                    icon: <HighlightOutlined />,
                    tooltip: "Click để chỉnh sửa",
                    onChange: (e) => updateItem("name", e),
                  }
                : false
            }
          >
            {data?.kyc?.name}
          </Typography.Text>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 2,
          }}
        >
          <ItemText
            label="Số CMND"
            value={data.kyc?.id_number}
            changeFunc={(e) => updateItem("id_number", e)}
            editable
          />
          <ItemText
            label="Ngày sinh"
            value={data.kyc?.dob}
            changeFunc={(e) => updateItem("dob", e)}
            editable
          />
          <ItemText
            label="Địa chỉ"
            value={data.kyc?.address}
            changeFunc={(e) => updateItem("address", e)}
            editable
          />
          <ItemText
            label="Nghề nghiệp"
            value={data.kyc?.job}
            changeFunc={(e) => updateItem("job", e)}
            editable
          />

          {/* <ItemText
            label="Giới tính"
            value={data.kyc?.sex}
            changeFunc={(e) => updateItem("sex", e)}
            editable
          /> */}

          <ItemText
            label="Thu nhập"
            value={data.kyc?.income}
            changeFunc={(e) => updateItem("income", e)}
            editable
          />
          <ItemText label="Mục đích vay" value={data?.kyc?.purpose} />
          <ItemText label="Thời gian gọi:" value={data?.selectTimeToCall} />
          <p
            style={{
              margin: "12px 0 0 0",
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            Thông tin người liên hệ 1
          </p>
          <ItemText
            label="Họ tên"
            value={data.kyc?.contact1Name}
            changeFunc={(e) => updateItem("contact1Name", e)}
            editable
          />
          <ItemText
            label="Số điện thoại"
            value={data.kyc?.contact1PhoneNumber}
            changeFunc={(e) => updateItem("contact1PhoneNumber", e)}
            editable
          />
          <ItemText
            label="Quan hệ người vay"
            value={data.kyc?.contact1Relationship}
            changeFunc={(e) => updateItem("contact1Relationship", e)}
            editable
          />
          <p
            style={{
              margin: "12px 0 0 0",
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            Thông tin người liên hệ 2
          </p>
          <ItemText
            label="Họ tên"
            value={data.kyc?.contact2Name}
            changeFunc={(e) => updateItem("contact2Name", e)}
            editable
          />
          <ItemText
            label="Số điện thoại"
            value={data.kyc?.contact2PhoneNumber}
            changeFunc={(e) => updateItem("contact2PhoneNumber", e)}
            editable
          />
          <ItemText
            label="Quan hệ người vay"
            value={data.kyc?.contact2Relationship}
            changeFunc={(e) => updateItem("contact2Relationship", e)}
            editable
          />

          {data.kyc.status != "accepted" && ROLE == "ROOT" && (
            <Button
              type="primary"
              style={{ marginTop: 30 }}
              onClick={() => updateItem("status", "accepted")}
            >
              Xác minh khách hàng
            </Button>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography.Title level={5}>
            Thông tin tài khoản thụ hưởng
          </Typography.Title>
          <SelectBank
            value={data?.kyc?.bank?.bankName}
            onChange={(e) => updateBank("bankName", e)}
            editable={ROLE === "ROOT"}
          />
          <ItemText
            label="Tên chủ thẻ"
            value={data?.kyc?.bank?.name}
            changeFunc={(e) => updateBank("name", e)}
            editable
          />
          <ItemText
            label="Số thẻ"
            value={data?.kyc?.bank?.number}
            changeFunc={(e) => updateBank("number", e)}
            editable
          />
          <ItemText
            label="CCCD của chủ thẻ"
            value={data?.kyc?.bank?.id_number_2}
            changeFunc={(e) => updateBank("id_number_2", e)}
            editable
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          background: "#fff",
          padding: 20,
          borderRadius: 10,
        }}
      >
        <Carousel
          images={[data.kyc.id_front, data.kyc.id_back, data.kyc.id_with_face, data?.kyc?.bank?.bankCardImageUrl]}
          setIsModalShowIdentity={setIsModalShowIdentity}
        />
      </div>
      <ModalShowIdentity
        images={[
          { key: "id_front", value: data.kyc.id_front },
          { key: "id_back", value: data.kyc.id_back },
          { key: "id_with_face", value: data.kyc.id_with_face },
          { key: "bankCardImageUrl", value: data?.kyc?.bank?.bankCardImageUrl },
        ]}
        isOpenModal={isModalShowIdentity}
        closeModal={() => setIsModalShowIdentity(false)}
        dispatchReload={dispatchReload}
        idUser={data._id}
        profile={data}
      />
    </div>
  );
}

const Phone = ({ userId, phone, editable, reload }) => {
  const [ROLE] = React.useState(localStorage.getItem("role"));

  async function changeFunc(e) {
    await api.put(`/users/${userId}/login-phone`, { userId, newPhone: e });
    message.success("Thành công.");
    reload();
  }
  return (
    <span
      style={{
        display: "flex",
        justifyContent: "space-between",
        paddingTop: 10,
      }}
    >
      <Typography.Text
        strong
        style={{ flex: 2 }}
        editable={
          editable && ROLE == "ROOT"
            ? {
                icon: <HighlightOutlined />,
                tooltip: "Click để chỉnh sửa",
                onChange: (e) => changeFunc(e),
              }
            : false
        }
      >
        {phone}
      </Typography.Text>
    </span>
  );
};
const Carousel = ({ images, setIsModalShowIdentity }) => {
  const [key, setKey] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    const timeout = setTimeout(() => setLoading((prev) => !prev), 250);
    return () => clearTimeout(timeout);
  }, [key]);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin spinning={loading} />
        </div>
      ) : (
        <div
          style={{
            padding: "8px",
            margin: "4px",
            border: "1px solid #ccc",
            position: "relative",
            height: "350px",
            width: "350px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <Image
            src={images[key]}
            preview={false}
            onClick={() => setIsModalShowIdentity(true)}
            style={{
              width: "auto",
              objectFit: "cover",
              maxWidth: "100%",
              maxHeight: "100%",
            }}
          />
        </div>
      )}
      <Typography.Text strong style={{ textAlign: "center" }}>
        {key === 0 && "Ảnh trước CMND"}
        {key === 1 && "Ảnh sau CMND"}
        {key === 2 && "Ảnh chân dung"}
        {key === 3 && "Ảnh thẻ"}
      </Typography.Text>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: 10,
          minWidth: "100%",
        }}
      >
        <Button disabled={key == 0} onClick={() => setKey((prev) => prev - 1)}>
          Trước
        </Button>
        <Button disabled={key == images.length - 1} onClick={() => setKey((prev) => prev + 1)}>
          Sau
        </Button>
      </div>
    </div>
  );
};
