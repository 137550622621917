/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useState } from 'react'
import api from '../../api'
import { Table, Typography, Space, Modal, Button, Select, Input, message, Popconfirm, Pagination, Popover } from 'antd'
import moment from 'moment'
import { EyeInvisibleOutlined, EyeOutlined, HighlightOutlined } from '@ant-design/icons'
import IconZalo from '../../assets/zalo-logo.png'
import IconFacebook from '../../assets/facebook-icon.png'
import isCorrectNumberPhone from '../../utils/isCorrectNumberPhone';
export default () => {
  const [onReload, setOnReload] = useState(false)
  const [referCodeList, setReferCodeList] = useState([])
  const [refetcher, setRefetcher] = useState(false)
  const [page, setPage] = React.useState(1)
  const [totalDocs, setTotalDocs] = useState(1)
  useEffect(() => {
    ; (async () => {
      const { data } = await api.get(`/userReferCode?page=${page}&limit=10`)
      setReferCodeList(data.docs)
      setTotalDocs(data.totalDocs)
    })()
  }, [refetcher, page])

  const [openDetail, setOpenDetail] = useState(false)
  const [currentData, setCurrentData] = useState([])
  const [visibleCustomPassword, setVisibleCustomPassword] = useState(null)
  const [customPassword, setCustomPassword] = useState('')

  const columns = [
    // {
    //   title: 'Hash key',
    //   dataIndex: '_id',
    //   key: '_id',
    // },
    {
      title: 'Mã giới thiệu',
      dataIndex: 'referCode',
      key: 'referCode',
      render: (text, record) => (
        <Typography.Text>
          {record.referCode}
        </Typography.Text>
      ),
    },
    {
      title: 'Tuỳ chọn',
      key: 'optional',
      render: (text, record) => (
        <Space>
          <Popconfirm title="Xoá ? Không thể khôi phục" onConfirm={() => deleteItem(record._id)}>
            <Button danger>Xoá</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ]
  
  async function changePasswordStaff({ password, userId }) {
    try {
      if (password == '') {
        message.error('Không được trống')
        return
      }
      await api.put('/auth/password', { userId, password })
      message.success('Reset password thành công.')
      setVisibleCustomPassword(null)
    } catch (err) {
      message.error('Đã có lỗi xảy ra. Vui lòng thử lại sau')
    }
  }

  const [openTransfer, setOpenTransfer] = useState(false)
  const [oldSupporter, setOldSupporter] = useState(null)
  const [newSupporter, setNewSupporter] = useState('')
  const [addNewReferCode, setAddNewReferCode] = useState(false)
  const [newReferCode, setNewReferCode] = useState('')
  const [password, setPassword] = useState('')
  const [name, setName] = useState('')
  const [isHidePass, setIsHidePass] = useState(true)

  async function create() {
    try {
      await api.post('/userReferCode', {
        referCode: newReferCode,
      })
      setNewReferCode('')
      message.success('Thêm thành công.')
      setRefetcher(prev => !prev)
      setAddNewReferCode(false)
    } catch (err) {
      message.error('Có lỗi xảy ra. Vui lòng thử lại sau.')
    }
  }

  async function deleteItem(id) {
    try {
      await api.delete(`/userReferCode/${id}`)
      message.success('Xoá thành công.')
      setRefetcher(prev => !prev)
    } catch (err) {
      message.error('Có lỗi xảy ra. Vui lòng thử lại sau.')
    }
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {!addNewReferCode ? (
          <Button onClick={() => setAddNewReferCode(true)} type="primary">
            Thêm
          </Button>
        ) : (
          <>
            <Space>
              <Input
                placeholder="Nhập mã giới thiệu"
                type="primary"
                onChange={e => setNewReferCode(e.target.value)}
              />
              <Button type="primary" onClick={create}>
                Thêm mới
              </Button>
              <Button onClick={() => setAddNewReferCode(false)}>Huỷ</Button>
            </Space>
          </>
        )}
      </div>
      <br />

      <Table dataSource={referCodeList} columns={columns} pagination={false} />
      <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 10 }}>
        <Pagination
          total={totalDocs}
          current={page}
          onChange={e => setPage(e)}
          pageSize={10}
          pageSizeOptions={['10']}
        />
      </div>
      <ModalDetail open={openDetail} data={currentData} onClose={() => setOpenDetail(false)} />
    </>
  )
}

export const ModalDetail = ({ open, onClose, data }) => {
  const columns = [
    {
      title: 'Số điện thoại khách hàng',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Thời gian nhận khách',
      dataIndex: 'time',
      key: 'time',
      render: (text, record) => (
        <Typography.Text>
          {moment(text || record.toSupportAt).format('hh:mm A, DD-MM-YYYY')}
        </Typography.Text>
      ),
    },
  ]
  return (
    <Modal visible={open} onOk={onClose} onCancel={onClose}>
      <Table columns={columns} dataSource={data} />
    </Modal>
  )
}
