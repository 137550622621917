import {
  CheckSquareOutlined,
  CloseSquareOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import {
  List,
  Row,
  Typography,
  Col,
  Avatar,
  message,
  Card,
  Input,
} from "antd";
import api from '../../api'
import React from "react";
import { useState } from "react";


function Reason() {
  const [isAdding, setIsAdding] = useState(false);
  const [contentInput, setContentInput] = useState(undefined);
  const [refetcher, setRefetcher] = useState(false)
  const [dataList, setDataList] = useState([
    // { index: 0, value: "red", label: "red" },
    // { index: 1, value: "blue", label: "blue" },
  ]);

  React.useEffect(() => {
    ;(async () => {
      getAllNote()
    })()
  }, [refetcher])

  function handleAdd() {
    setIsAdding(false);
    setContentInput(undefined);
    let _newDataList =[
      ...dataList,
      {
        index: dataList[dataList.length - 1]?.index + 1,
        value: contentInput,
        label: contentInput,
      },
    ]

    setDataList(_newDataList);
    let _newNoteList = [];
    _newDataList.forEach(content => {
      _newNoteList.push(content.value)
    });
    updateAllNote(_newNoteList);
  }
  function handleClear() {
    setIsAdding(false);
    setContentInput(undefined);
  }
  
  async function getAllNote() {
    const { data } = await api.get(`/agentNote/getAllNote`)
    
    if (data) {
      let _agentNotes = data.data
      if (_agentNotes && _agentNotes.length > 0) {
      let _newDataList = _agentNotes.map((content, index) => {
        return {
          index: index,
          value: content,
          label: content,
        }
      });
      setDataList(_newDataList)
      }
    }
  }
  async function updateAllNote(newDataList) {
    await api.post(`/agentNote/updateAllNote`, {
      data: newDataList,
    })

    message.success('Cập nhật thành công.')
  }
  function handleDelete(index) {
    let _newDataList = dataList.filter((item) => item.index !== index);
    let _newNoteList = [];
    setDataList(_newDataList);
    _newDataList.forEach(content => {
      _newNoteList.push(content.value)
    });
    updateAllNote(_newNoteList);
  }
  
  return (
    <div>
      <Row className="justify-content-center">
        <Col lg={16}>
          <Card>
            <List
              header={
                <div style={{ fontSize: "20px", fontWeight: "600" }}>
                  Quản lý báo lỗi
                </div>
              }
              itemLayout="horizontal"
              dataSource={dataList}
              bordered
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <CloseSquareOutlined
                      style={{
                        backgroundColor: "red",
                        padding: "2px",
                        borderRadius: "2px",
                        fontSize: "24px",
                        color: "white",
                      }}
                      onClick={() => handleDelete(item?.index)}
                    />,
                  ]}
                >
                  <span style={{ fontSize: "18px" }}>{item.value}</span>
                </List.Item>
              )}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row-reverse",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <div
                style={{
                  minWidth: "150px",
                  display: "flex",
                  justifyContent: "right",
                }}
              >
                {!isAdding ? (
                  <strong
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "5px 8px",
                      border: "1px solid #ccc",
                      backgroundColor: "#52c41a",
                      cursor: "pointer",
                      color: "#fff",
                      minWidth: "110px",
                      justifyContent: "center",
                    }}
                    onClick={() => setIsAdding(true)}
                  >
                    <span style={{ fontSize: "18px" }}>Thêm</span>
                    <PlusSquareOutlined
                      style={{ fontSize: "22px", marginLeft: "8px" }}
                    />
                  </strong>
                ) : (
                  <div
                    style={{
                      padding: "5px 8px",
                      border: "1px solid #ccc",
                      minWidth: "110px",
                      textAlign: "center",
                    }}
                  >
                    <CheckSquareOutlined
                      style={{
                        fontSize: "24px",
                        padding: "2px",
                        borderRadius: "2px",
                        backgroundColor: "#52c41a",
                        cursor: "pointer",
                        color: "#fff",
                      }}
                      onClick={() => {
                        handleAdd();
                      }}
                    />
                    <CloseSquareOutlined
                      style={{
                        fontSize: "24px",
                        marginLeft: "12px",
                        padding: "2px",
                        borderRadius: "2px",
                        backgroundColor: "red",
                        cursor: "pointer",
                        color: "#fff",
                      }}
                      onClick={() => {
                        handleClear();
                      }}
                    />
                  </div>
                )}
              </div>

              {isAdding && (
                <Input
                  size="large"
                  value={contentInput}
                  onChange={(e) => setContentInput(e.target.value)}
                ></Input>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Reason;
